import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function stockinboundUseList() {
  // Use toast
  const toast = useToast()

  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    // { key: 'id', label: '仓库入库单', sortable: true },
    { key: 'stockin_id', label: '仓库入库单', sortable: true },
   /* { key: 'stockin_no', label: '仓库入库单编号', sortable: true },*/
    { key: 'inbound_id', label: '入库通知单', sortable: true },
   /* { key: 'inbound_no', label: '采购入库单编号', sortable: true },*/
    { key: 'order_id', label: '采购订单', sortable: true },
   /* { key: 'order_no', label: '采购单编号', sortable: true },*/
    { key: 'warehouse_id', label: '入库仓库', sortable: true },
    /*{ key: 'warehouse_name', label: '仓库名称', sortable: true },*/
    { key: 'company_id', label: '主体', sortable: true },
    { key: 'delivery_type', label: '配送方式', sortable: true },
    { key: 'transport_type', label: '运输方式', sortable: true },
    { key: 'delivery_time', label: '发货时间', sortable: true },
    { key: 'arrival_time', label: '预计到达时间', sortable: true },
    { key: 'realarrival_time', label: '实际到达时间', sortable: true },
    { key: 'carrier', label: '承运商', sortable: true },
    { key: 'express_no', label: '快递单号', sortable: true },
    // { key: 'state', label: '状态', sortable: true },
    { key: 'add_time', label: '创建时间', sortable: true },
    { key: 'creator', label: '创建人', sortable: true },
/*        { key: 'actions', label: '操作' },*/
  ]

  const start = ref(1)
  const limit = ref(10)
  const limitOptions = [10, 25, 50, 100]
  const listTotals = ref(0)
  const searchKey = ref('')
  const orderBy = ref('stockin_id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start.value - 1) + localItemsCount,
      of: listTotals.value,
    }
  })

  const refetchData = () => {
	  refListTable.value.refresh()
  }

  watch([start, limit, searchKey], () => {
    refetchData()
  })

  const searchList = (ctx, callback) => {
    store
      .dispatch('stockinbound/search', {
        search_key: searchKey.value,
        start: start.value,
        limit: limit.value,
        order_by: orderBy.value,
        order_desc: isSortDirDesc.value === true ? 'desc':'',
      })
      .then(response => {
        const data = response.data.data
        const list = data.ext.list
        listTotals.value = data.ext.totals

        callback(list)
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: '列表获取错误',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    searchList,
    tableColumns,
    limit,
    start,
    listTotals: listTotals,
    dataMeta,
    limitOptions,
    searchKey,
    orderBy,
    isSortDirDesc,
    refListTable,

    refetchData,

    // Extra Filters
  }
}
