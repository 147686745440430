<template>

  <div>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
              v-model="limit"
              :options="limitOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchKey"
                class="d-inline-block mr-1"
                placeholder="搜索关键字..."
              />
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refListTable"
        class="position-relative"
        :items="searchList"
        responsive
        hover
        small
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="orderBy"
        show-empty
        empty-text="未找到记录"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- stockinbounditem detail on click -->
        <template #row-details="row">
          <b-card>
            <div>
              <b-table striped hover :items="row.item.ext.stockInboundItem"  :fields="fields">
                <!--商品69码-->
                <template #cell(ext)="data">
                  {{ data.item.ext.code ? data.item.ext.code : '' }}
                </template>

                <template #cell(name)="data">
                  {{ data.item.name }}
                </template>

                <!--生产日期-->
                <template #cell(production_date)="data">
                  {{toDate(data.item.production_date)}}
                </template>

                <!--有效期-->
                <template #cell(expiry_date)="data">
                  {{toDate(data.item.expiry_date)}}
                </template>

                <!--库存状态-->
                <template #cell(status)="data">
                  <b-badge
                          pill
                          :variant="`light-${getCodeColor('stock_status', data.item.status)}`"
                  >
                    {{ getCodeLabel("stock_status", data.item.status)}}
                  </b-badge>
                </template>

                <!--赠品-->
                <template #cell(is_gift)="data">
                  {{ isGift(data.item.is_gift) }}
                </template>

              </b-table>
            </div>
          </b-card>
        </template>

        <!-- Columns -->
        <template #cell(stockin_id)="data">
          <b-form-checkbox v-model="data.detailsShowing"
                           name="check-button"
                           @change="data.toggleDetails"
                           button-variant="default"
                           size="sm"
                           button>
            <feather-icon
                    icon="MinusSquareIcon"
                    size="15"
                    class="align-middle text-body"
                    v-show="data.detailsShowing==true"
            />
            <feather-icon
                    icon="PlusSquareIcon"
                    size="16"
                    class="align-middle text-body"
                    v-show="data.detailsShowing==false"
            />
          </b-form-checkbox>
          [#{{ data.item.stockin_id }}]{{ data.item.stockin_no }}
        </template>

        <!--采购入库[ID/编号]-->
        <template #cell(inbound_id)="data">
          [#{{ data.item.inbound_id }}]{{ data.item.inbound_no }}
        </template>

        <!--采购单[ID/编号]-->
        <template #cell(order_id)="data">
          [#{{ data.item.order_id }}]{{ data.item.order_no }}
        </template>

        <!--仓库[ID/编号]-->
        <template #cell(warehouse_id)="data">
          [#{{ data.item.warehouse_id }}]{{ data.item.warehouse_name }}
        </template>

        <!--货主-->
        <template #cell(company_id)="data">
          {{getCodeLabel('company', data.item.company_id)}}
        </template>

        <!--发货时间-->
        <template #cell(delivery_time)="data">
          {{toDate(data.item.delivery_time)}}
        </template>

        <!--预计到达时间-->
        <template #cell(arrival_time)="data">
          {{toDate(data.item.arrival_time)}}
        </template>

        <!--实际到达时间-->
        <template #cell(realarrival_time)="data">
          {{toDate(data.item.realarrival_time)}}
        </template>

        <!--创建时间-->
        <template #cell(add_time)="data">
          {{toDate(data.item.add_time)}}
        </template>

        <!--创建人-->
        <template #cell(creator)="data">
          {{getCodeLabel('user', data.item.creator)}}
        </template>

        <!-- Column: Type -->
        <template #cell(${classVar}_type)="data">
          {{getCodeLabel('stockinbound_type', data.item.stockinbound_type)}}
        </template>

        <!-- Column: State -->
        <template #cell(state)="data">
          <b-link
            @click="changeState(data.item)"
          >
          <b-badge
            pill
            :variant="`light-${getCodeColor('state', data.item.state)}`"

          >
            {{getCodeLabel('state', data.item.state)}}
          </b-badge>
          </b-link>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item :to="{ name: 'apps-stockinbound-view', query: { id: data.item.id } }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">浏览</span>
            </b-dropdown-item>

            <b-dropdown-item :to="{ name: 'apps-stockinbound-edit', query: { id: data.item.id } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">编辑</span>
            </b-dropdown-item>

          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="start"
              :total-rows="listTotals"
              :per-page="limit"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText, toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor } from '@core/utils/filter'
import stockinboundUseList from './stockinboundUseList'
import stockinboundStore from './stockinboundStore'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  data(){
    return {
      fields: [
        { key: 'ext', label: '商品69码'},
        { key: 'id', label: 'ID', sortable: true },
        { key: 'stock_id', label: 'StockId', sortable: true },
        { key: 'name', label: '商品名称', sortable: true },
        // { key: 'category_name', label: '分类名称', sortable: true },
        // { key: 'brand_name', label: '品牌名称', sortable: true },
        { key: 'batchno', label: '批次号', sortable: true },
        { key: 'production_date', label: '生产日期', sortable: true },
        { key: 'expiry_date', label: '有效日期', sortable: true },
        { key: 'qty', label: '入库数量', sortable: true },
        { key: 'cost', label: '含税单价', sortable: true },
        // { key: 'location_id', label: '仓位ID', sortable: true },
        { key: 'location_fullname', label: '仓位', sortable: true },
        { key: 'is_gift', label: '赠品', sortable: true },
        { key: 'status', label: '状态', sortable: true },
       /* { key: 'state', label: '状态', sortable: true },*/
      ],
    }
  },

  methods: {
    changeState: function(data) {
      const state = 1 - data.state
      store.dispatch('stockinbound/state', {id: data.id, state: state}).then(res => {
        data.state = state
      })
    },
  },
  setup() {
    // Register module
    if (!store.hasModule('stockinbound')) store.registerModule('stockinbound', stockinboundStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('stockinbound')) store.unregisterModule('stockinbound')
    })

    const isGift = function (params) {
      if (params === 1) {
        return "(赠)"
      } else {
        return "否"
      }
    }

    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // UI
    } = stockinboundUseList()

    return {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      // UI
      toDate,

      isGift,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
